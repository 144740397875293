import api from "../api";

export async function CheckPromoCode(promoCode) {
    try {
        const data = await api.get(`/content/items/Promo`);
        const promo = data.find(item => item.Code === promoCode);

        if (promo) {
            const currentDate = new Date();
            const expiryDate = new Date(promo['Expiry Date']);

            if (expiryDate >= currentDate) {
                return promo['Discount Percentage'];
            }
        }

        return false;
    } catch (err) {
        console.log(err);
        return false;
    }
}
import './App.css';
import { SiteHeader, SiteFooter } from './components/Header&Footer';
import Announcement from './components/Others/Announcement';
import { CartProvider } from "react-use-cart";
import Qodera from './components/Header&Footer/Qodera';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import Alert1 from './components/MyAlerts/Alert1';
import AnimatedRoutes from './pages/AnimatedRoutes';
import { API_URL } from './constant';
import useAnnouncement from './hooks/useAnnouncement';
import { useMemo } from 'react';
import HeroSection from './components/HeroSections/HeroSection';


function App() {
  const options = {
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    offset: '30px',
    containerStyle: {zIndex: 1002},
    transition: transitions.SCALE
  }
  const { announcementText, isLoading, error } = useAnnouncement(API_URL);
  const memoizedAnnouncementText = useMemo(() => announcementText, [announcementText]);
    

  return (
  <CartProvider>
    <AlertProvider template={Alert1}  {...options}>
      { isLoading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>Loading...</div> }
      { error && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>Error: {error}</div> }
      { memoizedAnnouncementText && <Announcement announcement={memoizedAnnouncementText} />}
      <SiteHeader />

      <AnimatedRoutes />

      <SiteFooter />
      <Qodera />
    </AlertProvider>
  </CartProvider>
);

}

export default App;
import axios from 'axios';
import { API_URL, AUTH_TOKEN } from "../constant";

class Api {
    token = AUTH_TOKEN;

    server = axios.create({
        baseURL: API_URL,
        headers: {
            'api-key': this.token
        }
    })

    /**
     * extract data from response
     * @param {import('axios').AxiosResponse} resp
     */
    extractData(resp) {
        return resp.data
    }

    async get(endpoint) {
        return this.server.get(endpoint, this.def_config)
            .then(this.extractData)
    }

    async post(endpoint, body) {
        return this.server.post(endpoint, body, this.def_config)
            .then(this.extractData)
    }

    async put(endpoint, body) {
        return this.server.put(endpoint, body, this.def_config)
            .then(this.extractData)
    }

    async del(endpoint) {
        return this.server.get(endpoint, this.def_config)
            .then(this.extractData)
    }

    getImageUrl(image, mode, width, height) {
        let extraParams = '';

        if (mode)
            extraParams += `&m=${mode}`

        if (width === undefined)
            width = 720
        extraParams += `&w=${width}`

        return `${API_URL}/assets/image/${image._id}?o=1${extraParams}`
    }
}

const api = new Api();

export default api;

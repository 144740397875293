import api from '../api';

export const UpdateQuantity = async (variantID, quantity) => {
    try {
        const response = await api.get(`/content/item/variant/${variantID}?populate=1`);
        let variant = response;
        if (variant.quantity < quantity) {
            return false;
        }
        let newQuantity = variant.quantity - quantity;
        await api.post(`/content/item/variant`, {
            data: {
                _id: variantID,
                "quantity": newQuantity
            }
        });
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}

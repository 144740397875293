import { CheckPromoCode } from "./CheckPromoCode";

export const handleApplyPromoCode = async (promoCode, cartTotal, alert, setIsPromoCodeApplied, setCartTotalAfterPromo, setTotalPrice, totalPrice) => {
        if (!promoCode) {
            alert.show('Please enter a promo code', { type: 'error' });
            return;
        }

        const promoPercentage = await CheckPromoCode(promoCode);

        if (promoPercentage) {
            setIsPromoCodeApplied(true);
            const discount = (promoPercentage / 100) * cartTotal;
            setCartTotalAfterPromo(cartTotal - discount);
            setTotalPrice(totalPrice - discount);
            alert.show(`Promo code applied successfully. You got ${promoPercentage}% discount`, { type: 'success' });
            return discount;
        }

        alert.show('Invalid promo code', { type: 'error' });
        // disable applying promo code again
        return 0;
}
import React from 'react'

export default function CartPricesSection(props) {
    const { subtotal } = props;
  return (
    <>
      <div className="flex justify-between mx-auto w-full">
        <p className="text-sm text-gray-600">Subtotal</p>
        <p className="text-sm text-gray-900">{subtotal} EGP</p>
      </div>
      <div className="flex justify-between mx-auto w-full">
        <p className="text-sm text-gray-600">Shipping</p>
        <p className="text-sm text-gray-600"> Calculated at Checkout</p>
      </div>
      <div className="flex justify-between mx-auto w-full">
        <p className="text-sm text-gray-600">Total</p>
        <p className="text-sm text-gray-600">{subtotal} + Shipping</p>
      </div>
    </>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

export default function NotFound() {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="md:h-screen">
    <div className="flex items-center h-screen">
        <div className="justify-center max-w-6xl px-4 mx-auto text-center ">
        <div className="w-full p-8 bg-white rounded-md dark:bg-gray-800">
            <div className="hidden w-full mb-6 xl:block lg:block">
            </div>
            <h1 className="inline-block mb-5 font-bold text-maincolor text-7xl xl:text-9xl lg:text-9xl dark:text-gray-300">
            404
            </h1>
            <h2 className="mb-4 text-2xl font-semibold text-gray-800 lg:text-4xl">
            Oops! Something wents wrong!
            </h2>
            <p className="mb-5 text-xs text-gray-800 dark:text-gray-400">
            Sorry! we are unable to find the page that you are looking for...
            </p>
            <div className="flex flex-wrap items-center justify-center">
                <Link
                    to={'/'}
                    className="px-4 py-3 mb-8 mr-0 text-base font-medium text-gray-100 uppercase bg-maincolor hover:bg-secondarycolor lg:mb-0 lg:mr-4 md:w-auto transition"
                >
                    Back to home
                </Link>
            </div>
        </div>
        </div>
    </div>
    </motion.div>

  )
}

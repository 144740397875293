import api from "../../helper/api";

export const CitiesData = async () => {
    let cities = [];
    try {
        const data = await api.get("/content/items/Shipping?populate=1");
        cities = data.map((city) => ({
            id: city.id,
            name: city.city,
            shippingPrice: city.price,
        }));
    }
    catch (error) {
        console.log(error);
    }
    return cities;
}